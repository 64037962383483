import React from 'react'

import {captureException} from '~/modules/sentry'
import Error from '~p/_error'

const WithDefault = App => class WithDefender extends React.Component {
  static async getInitialProps(context) {
    const {ctx} = context
    const {res} = ctx

    // Handle resource not found
    if (res) {
      const {statusCode} = res

      if (statusCode >= 400) {
        return {
          hasError: true,
          statusCode,
        }
      }
    }

    // Default code path
    try {
      const appProps = typeof App.getInitialProps === 'function'
        ? await App.getInitialProps(context)
        : {}

      return appProps
    }
    catch (err) {
      console.debug('WithDefender.getInitialProps catch')
      const errorStack = err.stack
      const statusCode = err.statusCode ?? 500

      if (res) {
        res.statusCode = statusCode
      }

      const errorEventId = captureException(err, ctx)

      return {
        hasError: true,
        statusCode,
        errorEventId,
        errorStack,
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.router.asPath !== state.asPath) {
      // first time rendering on new path, respect the result from getInitialProps
      // the getInitialProps is always called when router.asPath changes
      return {
        asPath: props.router.asPath,
        hasError: props.hasError || false,
        statusCode: props.statusCode || undefined,
        errorEventId: props.errorEventId || undefined,
        errorStack: props.errorStack || undefined,
      }
    }
    else {
      // respect the result from state as error might have been thrown
      return {
        hasError: state.hasError || props.hasError || false,
        statusCode: state.statusCode || props.statusCode || undefined,
        errorEventId: state.errorEventId || props.errorEventId || undefined,
        errorStack: state.errorStack || props.errorStack || undefined,
      }
    }
  }

  static getDerivedStateFromError(err) {
    const {statusCode} = err
    const errorStack = err.stack

    return {
      hasError: true,
      statusCode,
      errorStack,
    }
  }

  componentDidCatch(error, errorInfo) {
    const {asPath} = this.state
    const errorEventId = captureException(error, {asPath, errorInfo})

    this.setState({errorEventId})
  }

  constructor(props) {
    super(props)
    this.state = {
      asPath: undefined,
      hasError: false,
      statusCode: undefined,
      errorEventId: undefined,
    }
  }

  render() {
    const {hasError: _propHasError, statusCode: _propStatusCode, ...props} = this.props
    const {hasError, statusCode, errorEventId, errorStack} = this.state

    return hasError
      ? <Error
        statusCode={statusCode}
        errorEventId={errorEventId}
        errorStack={errorStack} />
      : <App {...props} />
  }
}

export default WithDefault
