import React from 'react'
import {ApolloProvider} from '@apollo/client'

import config from '~/config'
import {GraphQLClientError, GraphQLServerError} from '~/exception'
import getOrCreateClient from '~/modules/apollo/getOrCreateClient'

const WithApolloProvider = App => class WithApolloProvider extends React.Component {
  static async getInitialProps(context) {
    const appProps = typeof App.getInitialProps === 'function'
      ? await App.getInitialProps(context)
      : {}
    const apolloCache = config.isServer
      ? await this.getApolloCache(context)
      : {}

    return {
      ...appProps,
      apolloCache,
    }
  }

  static async getApolloCache(context) {
    const apolloClient = getOrCreateClient()
    const {Component} = context
    const {query, variables, callback} = typeof Component.getApolloQueryProperties === 'function'
      ? Component.getApolloQueryProperties(context)
      : {}

    if (query) {
      let res

      try {
        res = await apolloClient.query({
          query,
          variables,
        })
      }
      catch (e) {
        if (e.networkError?.statusCode >= 400 && e.networkError?.statusCode <= 499) {
          throw new GraphQLClientError(e.networkError.result?.errors?.[0]?.message)
        }
        throw new GraphQLServerError(e)
      }

      if (callback) {
        callback(res)
      }
    }

    return apolloClient.cache.extract()
  }

  constructor(props) {
    super(props)
    this.apolloClient = typeof props.apolloClient === 'undefined'
      ? getOrCreateClient(props.apolloCache)
      : props.apolloClient
  }

  render() {
    return (
      <ApolloProvider client={this.apolloClient}>
        <App {...this.props} />
      </ApolloProvider>
    )
  }
}

export default WithApolloProvider
