import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'

import config from '~/config'
import rootReducers from './reducers'

let reduxStore = null

function initStore(initialState = {}) {
  const middlewares = []

  return createStore(
    rootReducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )
}

export default function getOrCreateStore(initialState) {
  if (config.isServer) {
    return initStore(initialState)
  }

  if (!reduxStore) {
    reduxStore = initStore(initialState)
  }

  return reduxStore
}
