import React from 'react'
import Head from 'next/head'

import config from '~/config'

export function themePath(name) {
  switch (config.env) {
    case 'production':
    case 'staging':
    case 'test':
      return `${config.cdn}${config.style.loadPath}/${name}/index.css`
    default:
      return `${config.cdn}${config.style.loadPath}/${name}/index.css`
  }
}

export function LinkCss({href}) {
  return (
    <React.Fragment>
      <Head>
        <link
          key={href}
          rel="preload"
          as="style"
          href={href} />
      </Head>
      <link
        rel="stylesheet"
        href={href} />
    </React.Fragment>
  )
}