import path from 'path'
import pkg from '~/../package'

let config = {
  env: process.env.APP_ENV || 'development',
  isServer: typeof window === 'undefined',
  version: pkg.version,
  root: path.dirname(path.dirname(__dirname)),
}

config = {
  ...config,
  ga: {trackerID: 'G-9J6R9LLM5V'},
  adsense: {caPub: 'ca-pub-3026964677122494'},
  dfp: {
    networkID: '21679299224',
    adUnits: {
      'bc-billboard': [[970, 250], [1, 1]],
      'bc-rectangle-a': [[300, 250]],
      'bc-rectangle-c': [[300, 250]],
      'bc-in-read': [[300, 250], [1, 1]],
      'bc-in-feed': [[300, 250]],
    },
  },
  sentry: {
    dsn: 'https://947df1d156104ec8950c160f2d01b74a@o302898.ingest.sentry.io/6690129',
    release: `${pkg.name}@${config.env}-${config.version}`,
  },
  style: {
    rootSrc: `${config.root}/styles`,
    rootDest: `${config.root}/public/z/${config.version}/styles`,
    loadPath: `/z/${config.version}/styles`,
  },
  locale: {
    rootSrc: `${config.root}/locales`,
    rootDest: `${config.root}/public/z/${config.version}/locales`,
    loadPath: `/z/${config.version}/locales`,
  },
  nextAssetPrefix: `/z/${config.version}`,
}

switch (config.env) {
  case 'production':
    config = {
      ...config,
      debug: false,
      baseurl: 'https://www.brandcase.co',
      cdn: 'https://s2.brandcase.co',
      graphql: {
        uri: 'https://graph-wp.ltman.com/',
        wordpress: 'brandcase',
      },
    }
    break
  case 'staging':
    config = {
      ...config,
      debug: false,
      baseurl: 'https://www.staging.brandcase.co',
      cdn: 'https://s2.staging.brandcase.co',
      graphql: {
        uri: 'https://graph-wp.staging.ltman.com/',
        wordpress: 'brandcase',
      },
    }
    break
  case 'test':
    config = {
      ...config,
      debug: false,
      baseurl: 'https://www.staging.brandcase.co',
      cdn: 'https://s2.staging.brandcase.co',
      graphql: {
        uri: 'https://graph-wp.staging.ltman.com/',
        wordpress: 'brandcase',
      },
      style: {
        ...config.style,
        rootDest: `${config.root}/public/z/test/styles`,
        loadPath: '/z/test/styles',
      },
    }
    break
  default:
    config = {
      ...config,
      debug: process.env.NODE_ENV !== 'production',
      baseurl: 'http://localhost:3000',
      cdn: 'http://cdn.localhost:3000',
      graphql: {
        uri: 'https://graph-wp.staging.ltman.com/',
        wordpress: 'brandcase',
      },
      nextAssetPrefix: '',
    }
    break
}

export default config
