import {InMemoryCache} from '@apollo/client'
import deepmerge from 'deepmerge'

const typePolicies = {
  ArchiveList: {keyFields: ['year', 'month']},
  PostList: {
    fields: {
      range: {
        merge: (existing = {}, incoming) => deepmerge(existing, incoming),
        keyArgs: false,
      },
    },
  },
  Category: {keyFields: ['term_id']},
  Tag: {keyFields: ['term_id']},
}

export default function createCache() {
  return new InMemoryCache({typePolicies})
}
