import React from 'react'
import {Provider} from 'react-redux'

import config from '~/config'
import getOrCreateStore from '~/modules/redux/getOrCreateStore'

const WithReduxProvider = App => class WithReduxProvider extends React.Component {
  static async getInitialProps(context) {
    const reduxStore = getOrCreateStore()

    context.ctx.reduxStore = reduxStore

    const appProps = typeof App.getInitialProps === 'function'
      ? await App.getInitialProps(context)
      : {}
    const reduxCache = config.isServer
      ? reduxStore.getState()
      : {}

    return {
      ...appProps,
      reduxCache,
    }
  }

  constructor(props) {
    super(props)
    this.reduxStore = typeof props.reduxStore === 'undefined'
      ? getOrCreateStore(props.reduxCache)
      : props.reduxStore
  }

  render() {
    return (
      <Provider store={this.reduxStore}>
        <App {...this.props} />
      </Provider>
    )
  }
}

export default WithReduxProvider
