import NextI18Next from 'next-i18next'

import config from '~/config'
import interpolation from './interpolation'

const i18nConfig = {
  defaultLanguage: 'th',
  otherLanguages: ['th'],
  defaultNS: 'common',
  localePath: config.locale.loadPath,
  localeStructure: '{{lng}}/{{ns}}',
  localeExtension: 'json',
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  detection: {},
  interpolation,
  //In NextJs with SSR Suspense is not supported yet. https://stackoverflow.com/a/69025210
  react: {useSuspense: false},
}

// Adapted from https://github.com/isaachinman/next-i18next/blob/v1.2.0/src/config/create-config.ts#L47

if (config.isServer) {
  // TODO: Move this to i18nConfig.localePath and ensure it works on storybook
  i18nConfig.localePath = `/public/${i18nConfig.localePath}`
  i18nConfig.backend = {
    loadPath: `${config.root}${i18nConfig.localePath}/${i18nConfig.localeStructure}.${i18nConfig.localeExtension}`,
    addPath: `${config.root}${i18nConfig.localePath}/${i18nConfig.localeStructure}.missing.${i18nConfig.localeExtension}`,
  }
}
else {
  i18nConfig.backend = {
    loadPath: `${config.cdn}${i18nConfig.localePath}/${i18nConfig.localeStructure}.${i18nConfig.localeExtension}`,
    addPath: `${config.cdn}${i18nConfig.localePath}/${i18nConfig.localeStructure}.missing.${i18nConfig.localeExtension}`,
  }
}

const i18nNext = new NextI18Next(i18nConfig)

export default i18nNext
export const {
  appWithTranslation,
  useTranslation,
  i18n,
} = i18nNext
