const MONTHS = {
  short: {
    th: [
      'ม.ค.',
      'ก.พ.',
      'มี.ค.',
      'เม.ย.',
      'พ.ค.',
      'มิ.ย.',
      'ก.ค.',
      'ส.ค.',
      'ก.ย.',
      'ต.ค.',
      'พ.ย.',
      'ธ.ค.',
    ],
  },
  long: {
    th: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
  },
}

function monthShort(val, lng) {
  const month = val - 1

  return MONTHS.short[lng][month % 12]
}

function monthLong(val, lng) {
  const month = val - 1

  return MONTHS.long[lng][month % 12]
}

function dateShort(val, lng) {
  const date = val.getDate()
  const month = val.getMonth()
  const year = val.getFullYear()

  return `${date} ${MONTHS.short[lng][month % 12]} ${year}`
}

function datetimeFull(val, lng) {
  const date = val.getDate()
  const month = val.getMonth()
  const year = val.getFullYear()
  const hour = val.getHours()
  const minute = val.getMinutes()
  const second = val.getSeconds()

  const n = a => `0${a}`.slice(-2)

  return `${date} ${MONTHS.long[lng][month % 12]} ${year} · ${n(hour)}:${n(minute)}:${n(second)}`
}

module.exports = {
  dateShort,
  datetimeFull,
  monthLong,
  monthShort,
}