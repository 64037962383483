import React from 'react'
import Head from 'next/head'
import Link from 'next/link'

import config from '~/config'
import {LinkCss, themePath} from '~/utils/theme'
import LogoBrand from '~/components/LogoBrand'

const statusCodes = {
  400: 'Bad Request',
  404: 'This page could not be found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
}

export default function PageError({statusCode, errorEventId, errorStack}) {
  const title = statusCodes[statusCode] ?? 'An unexpected error has occurred'

  return (
    <React.Fragment>
      <Head>
        <link
          rel="stylesheet"
          href={themePath('tailwindcss')} />
        <title>{statusCode}: {title}</title>
      </Head>
      <div className="font-fc container xl:max-w-screen-lg">
        <div className="flex justify-center text-5xl mt-16">
          <LogoBrand withBg />
        </div>
        <div className="text-center mt-4">
          <h4 className="mb-2 font-bold text-toggle">
            {statusCode}
          </h4>
          <h5 className="mb-2 font-bold text-toggle">
            {title}
          </h5>
        </div>
        {errorEventId && (
          <div className="text-toggle text-center mt-8">
            The error has been logged. Our engineers are working on it.<br />
            Error ID: {errorEventId}
          </div>
        )}
        <div className="text-center mt-8">
          <Link href="/">
            <a className="text-toggle hover:text-toggle font-bold">
              Go to Home
            </a>
          </Link>
        </div>
        {config.debug && errorStack && (
          <div
            className="mt-8 px-2 text-toggle border border-secondary overflow-auto"
            style={{maxHeight: 300}}>
            <pre>
              <code style={{fontSize: 12}}>{errorStack}</code>
            </pre>
          </div>
        )}
      </div>
      <LinkCss
        key="bc-webfonts"
        href={themePath('bc-webfonts')} />
      <LinkCss
        key="fc-subject-rounded-webfonts"
        href={themePath('fc-subject-rounded-webfonts')} />
    </React.Fragment>
  )
}