import {ApolloLink, HttpLink} from '@apollo/client'
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries'
import {setContext} from '@apollo/client/link/context'
import {sha256} from 'crypto-hash'

import config from '~/config'

const persistedQueryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
})

const authLink = setContext(() => {
  if (config.isServer) {
    return {
      headers: {
        'X-Platform': 'Web-SSR',
        'X-App': `Brandcase-${config.version}`,
        'X-Wordpress': config.graphql.wordpress,
      },
    }
  }
  else {
    return {
      headers: {
        'X-Platform': 'Web',
        'X-App': `Brandcase-${config.version}`,
        'X-Wordpress': config.graphql.wordpress,
      },
    }
  }
})

const httpLink = new HttpLink({
  uri: config.graphql.uri,
  credentials: 'same-origin',
})

export default ApolloLink.from([authLink, persistedQueryLink, httpLink])
