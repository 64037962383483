import * as Sentry from '@sentry/nextjs'

import config from '~/config'
import {AppError} from '~/exception'
import ignoreErrors from '~/modules/sentry/ignoreErrors'
import blacklistUrls from '~/modules/sentry/blacklistUrls'

export const sentryConfig = {
  dsn: config.sentry.dsn,
  release: config.sentry.release,
  environment: config.env,
  integrations: integrations => integrations.filter(integration => ['InboundFilters', 'Breadcrumbs', 'UserAgent'].includes(integration.name)),
  maxBreadcrumbs: 50,
  ignoreErrors,
  blacklistUrls,
  beforeSend(event, hint) {
    const error = hint.originalException
    const suppressed = ['development', 'storybook', 'test'].includes(config.env) ||
      (error instanceof AppError && error.sentrySuppressed)

    if (suppressed) {
      console.log('Suppressed Sentry Log')
      console.log({...event, breadcrumbs: '{removed}', sdk: '{removed}'})

      return null
    }

    return event
  },
}

export function captureException(err, ctx) {
  let eventId = null

  Sentry.withScope(scope => {
    const isAppError = err instanceof AppError

    scope.setLevel(isAppError ? 'info' : 'error')
    scope.setFingerprint([err.constructor.name, '{{ default }}'])
    scope.setTag('ssr', config.isServer)

    if (ctx) {
      const {asPath, req, res, errorInfo} = ctx

      if (asPath) {
        scope.setTag('asPath', asPath)
      }

      if (req && req.headers) {
        const headers = req.headers

        if (headers['user-agent']) {
          scope.setExtra('userAgent', headers['user-agent'])
        }

        if (headers['referer']) {
          scope.setTag('referer', headers['referer'])
        }
      }

      if (res && res.statusCode) {
        scope.setTag('statusCode', res.statusCode)
      }

      if (errorInfo) {
        Object.keys(errorInfo).forEach(key => scope.setExtra(key, errorInfo[key]),
        )
      }
    }

    eventId = Sentry.captureMessage(err)
  })

  return eventId
}