import React from 'react'
import App from 'next/app'
import {compose} from 'recompose'

import withDefender from '~/hocs/withDefender'
import withReduxProvider from '~/hocs/withReduxProvider'
import withApolloProvider from '~/hocs/withApolloProvider'
import {appWithTranslation} from '~/i18n'

import {dfpMount} from '~/modules/dfp'

const NoLayout = ({children}) => children

class DefaultApp extends App {
  static async getInitialProps(context) {
    const {pageProps, ...props} = await super.getInitialProps(context)

    return {
      ...props,
      pageProps: {
        namespacesRequired: ['common', 'datetime', 'post'],
        ...pageProps,
      },
    }
  }

  componentDidMount() {
    dfpMount()
  }

  render() {
    const {Component, pageProps} = this.props
    const Layout = Component.Layout ?? NoLayout

    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    )
  }
}

export default compose(
  withDefender,
  appWithTranslation,
  withReduxProvider,
  withApolloProvider,
)(DefaultApp)
