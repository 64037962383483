const date = require('./date')

module.exports = {
  escapeValue: false,
  formatSeparator: ',',

  // TODO: Remove default value 'th' from argument lng
  // There is an unreported bug at next-i18next@4.1.0 that cause lng to be undefined,
  // despite that it was initialized through middleware
  format: (val, format, lng = 'th') => {
    switch (format) {
      case 'datetime_full':
        return date.datetimeFull(val, lng)
      case 'date_short':
        return date.dateShort(val, lng)
      case 'month_short':
        return date.monthShort(val, lng)
      case 'month_long':
        return date.monthLong(val, lng)
      default:
        return val
    }
  },
}
