import {ApolloClient} from '@apollo/client'

import config from '~/config'
import apolloLink from './apolloLink'
import createCache from './createCache'

let apolloClient = null

function createClient(initialState) {
  return new ApolloClient({
    link: apolloLink,
    cache: createCache().restore(initialState ?? {}),
    connectToDevTools: !config.isServer,
    ssrMode: config.isServer,
  })
}

export default function getOrCreateClient(initialState) {
  if (config.isServer) {
    return createClient(initialState)
  }

  if (!apolloClient) {
    apolloClient = createClient(initialState)
  }

  return apolloClient
}
