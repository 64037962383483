import * as Sentry from '@sentry/nextjs'

import {sentryConfig} from '~/modules/sentry'

Sentry.init({
  ...sentryConfig,
  integrations: integrations => [
    ...sentryConfig.integrations(integrations),
    new Sentry.Integrations.BrowserTracing({tracingOrigins: [/^\//]}),
  ],
})
