import config from '~/config'

module.local = {mounted: false}

export function dfpMount() {
  if (!config.isServer) {
    if (module.local.mounted) {
      return
    }

    module.local.mounted = true

    googletag.cmd.push(() => {
      // https://support.google.com/admanager/answer/4578089?hl=en&ref_topic=4390040
      // # Tagging pages with infinite content
      googletag.pubads().enableSingleRequest()
      googletag.pubads().disableInitialLoad()
      googletag.pubads().collapseEmptyDivs(true)
      googletag.enableServices()
    })
  }
}
