export class AppError extends Error {
  constructor() {
    super(...arguments)
    this.statusCode = 500
    this.sentrySuppressed = false
  }
}

export class PageNotFound extends AppError {
  constructor() {
    super(...arguments)
    this.statusCode = 404
    this.sentrySuppressed = true
  }
}

export class GraphQLClientError extends AppError {
  constructor() {
    super(...arguments)
    this.statusCode = 500
    this.sentrySuppressed = false
  }
}
export class GraphQLServerError extends AppError {
  constructor() {
    super(...arguments)
    this.statusCode = 500
    this.sentrySuppressed = false
  }
}
